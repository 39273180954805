import React from "react";

import uroboLogo from "../../assets/urobo-logo-white.svg";
import atlatszoLogo from "../../assets/AtlatszoHang_logo_zold.svg";

const Footer = () => (
  <footer className="flex-item flex-container padding-small flex-justify-between flex-align-center">
    <div className="flex-container flex-align-center">
      <img
        src={uroboLogo}
        alt="Urobo, a game that never ends"
        width="60"
        height="auto"
        style={{ opacity: 0.65 }}
      />
      <img
        src={atlatszoLogo}
        alt="Urobo, a game that never ends"
        width="40"
        height="auto"
        style={{ opacity: 1, marginLeft: "1rem" }}
      />
    </div>
    <small>
      Copyright ©2011-{new Date().getFullYear()} Váczi Dániel, László Pál
    </small>
  </footer>
);

export default Footer;
