import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';

import { LanguageContext } from "../../contexts/LanguageContext";
import { texts } from "../../intl/lang";

import { RANKS } from '../../constants/ranks';

function AddFriend(props) {
  const { firebase, users, friends, requests, incoming, setRequests, setIncoming } = props;

  const currentUid = props.authUser.uid;

  const { language } = useContext(LanguageContext);

  const handleFriendRequest = friendUid => {
    return () => {
      return firebase.newRequest(currentUid, friendUid, requests, incoming)
    }
  };

  const handleDeleteRequest = friendUid => {
    return () => {
      return firebase.deleteRequest(currentUid, friendUid, requests, incoming, setRequests, setIncoming)
    };
  };

  const handleAccept = (friendUid) =>
    () => firebase.acceptRequest(currentUid, friendUid, friends, requests, incoming, setRequests, setIncoming);

  const handleDecline = (friendUid) => () =>
    firebase.declineRequest(currentUid, friendUid, requests, incoming, setRequests, setIncoming);

  // console.log('****** requests', friends, requests);
  return (
    <div className="padding">
      <div className="flex-container flex-align-center margin-bottom">
        <Link className="margin-right Icon" to={'/friends'} >⬅︎</Link>
        <h1 className="no-margin">{texts[language].addFriend['title']}</h1>
      </div>
      <ul className="list-group">
        {users
          ? Object.keys(users).map(key => (
            key !== currentUid &&
            <li key={key}>
            <span style={{ flex: '1 0 auto' }}>
              {users[key].username}
              {users[key].points ? ` (${users[key].points} pts)`: ' (0 pts)'}
              {users[key].points && users[key].points >= RANKS.MASTER && users[key].points < RANKS.GRAND_MASTER &&
                <span className="rank-master"> ♛ </span>}
              {users[key].points && users[key].points >= RANKS.GRAND_MASTER &&
                <span className="rank-grand-master"> ♚ </span>}
            </span>
            {friends && friends[key] &&
              <span>✓</span>
            }
            {requests && requests[key] &&
              <span style={{ cursor: "pointer" }} onClick={handleDeleteRequest(key)}>{texts[language].friends['cancelRequest']}</span>
            }
            {!(requests[key] || friends[key] || incoming[key]) &&
              <span style={{ cursor: "pointer" }} onClick={handleFriendRequest(key)}>{texts[language].friends['request']}</span>
            }
            {incoming[key] &&
              <>
                <span style={{ cursor: "pointer", marginRight: '1rem' }} onClick={handleAccept(key)}>{texts[language].friends['acceptRequest']}</span>
                <span style={{ cursor: "pointer" }} onClick={handleDecline(key)}>{texts[language].friends['declineRequest']}</span>
              </>
            }
            </li>
          ))
          : null
        }
      </ul>
    </div>
  );
}

const condition = authUser => !!authUser;

export default compose (
  withFirebase,
  withAuthorization(condition)
)(AddFriend);
