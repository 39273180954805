import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';

import { LanguageContext } from "../../contexts/LanguageContext";
import { texts } from "../../intl/lang";

const GamesList = ({ users, results, host, guest }) => (
    <ul className="list-group margin-top">
      {results && Object.keys(results)
          .reverse()
          .map(key => (
            <li key={key} className="flex-container">
              <span className="flex-grow-1">
                <span className={results[key][2] === 0 ? 'bold' : ''}>
                  {users[host].username}
                </span>
                <span className="color-red">
                  {results[key][3] === 0 && '⚑'}
                </span>
                <span> : </span>
                <span className={results[key][2] === 1 ? 'bold' : ''}>
                  {users[guest].username}
                </span>
                <span className="color-red">
                  {results[key][3] === 1 && '⚑'}
                </span>
              </span>
              <span className="margin-left">
                {results[key][0]} : { results[key][1]}
              </span>
            </li>
          ))
      }
    </ul>
  );

const TotalScore = ({ users, host, guest, results }) => {
  const winnedGames = results && Object.keys(results).map(key => results[key][2]);
  const scoreHost = results ? winnedGames.filter(e => e === 0).length : 0;
  const scoreGuest = results ? winnedGames.filter(e => e === 1).length : 0;
  return (
    <div className="flex-container flex-justify-stretch padding-top padding-bottom">
      <span className={`flex-grow-1 flex-container flex-column flex-justify-center flex-align-center ${scoreHost > scoreGuest && 'bold'}`}>
        <span className="Score__number">{scoreHost}</span>
        <span>{users[host].username}({users[host].points || 0})</span>
      </span>
      <span className={`flex-grow-1 flex-container flex-column flex-justify-center flex-align-center ${scoreHost < scoreGuest && 'bold'}`}>
        <span className="Score__number">{scoreGuest}</span>
        <span>{users[guest].username}({users[guest].points || 0})</span>
      </span>
    </div>
  )
}

function Games(props) {
  const { history, users, tables, match: { params: { tableId } } } = props;
  const table = tables[tableId] || null;
  const onBack = () => {
    history.goBack();
  }
  const { language } = useContext(LanguageContext);

  return (
    <div className="padding">
      <div className="flex-container flex-align-center margin-bottom">
        <span className="margin-right Icon" onClick={onBack} >⬅︎</span>
        <h1 className="no-margin">{texts[language].games['title']}</h1>
      </div>
      {table && users &&
        <>
          <TotalScore
            results={table.results}
            users={users}
            guest={table.guest}
            host={table.host}
          />
          <GamesList
            results={table.results}
            users={users}
            guest={table.guest}
            host={table.host}
          />
        </>
      }
    </div>
  )
}

export default withRouter(Games);
