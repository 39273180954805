import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

import { LanguageContext } from "../../contexts/LanguageContext";
import { texts } from "../../intl/lang";

const SignOutButton = ({ firebase, history }) => {
  const { language } = useContext(LanguageContext);

  const signout = () => {
    history.push(ROUTES.LANDING);
    firebase.doSignOut();
  }
  return (
  <button type="button" className="button-link" onClick={signout}>
    {texts[language].nav['signOut']}
  </button>
  );
}
export default withRouter(withFirebase(SignOutButton));
