import React, { useContext } from 'react';
import { LanguageContext } from "../../contexts/LanguageContext";
import { texts } from "../../intl/lang";
import { SignUpLink } from '../SignUp';
import gameImage from "../../assets/urobo_phone-1.png"
import hierarchyImage from "../../assets/urobo_tablet-1.png"

const Component = () => {
  const { language } = useContext(LanguageContext);

  return (
  <div className="flex-grow-1 flex-container flex-column">
    <div className="flex-item flex-grow-1 hero bg-red ">
      <div className="flex-container constrain-width-1024">
        <div className="padding flex-col">
          <h1>{texts[language].landing.hero['title']}</h1>
          <h2>{texts[language].landing.hero['tagline']}</h2>
          <h3>{texts[language].landing.hero['description']}</h3>
          <SignUpLink button />
        </div>
        <img
          src={gameImage}
          width="33%"
          height="33%"
          alt="Urobo - The game that never ends"
          className="flex-shrink-1 responsive flex-col"
        />
      </div>
    </div>
    <div className="flex-item flex-grow-2 bg-white">
      <div className="flex-container constrain-width-1024">
        <div className="padding flex-col">
          <h2>{texts[language].landing.circularHierarchy['title']}</h2>
          <ol style={{ marginLeft: "5vmin" }} className="align-left flex-shrink-1">
            {texts[language].landing.circularHierarchy['description'].map(text => (
                <li key={text}>{text}</li>
              ))}
          </ol>
        </div>
        <img
          src={hierarchyImage}
          width="50%"
          height="50%"
          alt="Urobo - The game that never ends"
          className="flex-shrink-1 responsive flex-col"
        />
      </div>
    </div>
    <div className="flex-item flex-grow-2 bg-light-grey padding">
      <h2 className="align-left margin-top">
        {texts[language].howToPlay.title}
      </h2>
      <ol style={{ marginLeft: "2vh" }} className="align-left">
        {texts[language].howToPlay.rules.map(text => (
          <li key={text}>{text}</li>
        ))}
      </ol>
    </div>
  </div>
)};

export default Component;
