import React, { useContext } from "react";
import { LanguageContext } from "../../contexts/LanguageContext";
import { texts } from "../../intl/lang";

export default function Button({ onClick, text, secondary }) {
  const { language } = useContext(LanguageContext);

  return (
    <button onClick={onClick} title={texts[language].buttons[text]} className={secondary && 'button-secondary'}>
      {texts[language].buttons[text]}
    </button>
  );
}
