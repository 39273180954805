export const LANDING = '/';
export const SIGN_UP = '/signUp';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const FRIENDS = '/friends';
export const ADD_FRIEND = '/add-friend';
export const GAME = '/games/:tableId/:gameId';
export const GAMES = '/games/:tableId';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const PASSWORD_FORGET = '/password-forget';
