import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { compose } from "recompose";
import { withFirebase } from "../Firebase";
import { withAuthorization } from "../Session";
import { LanguageContext } from "../../contexts/LanguageContext";
import { texts } from "../../intl/lang";

import { RANKS } from "../../constants/ranks";

function Friends(props) {
  const {
    firebase,
    users,
    tables,
    friends,
    requests, 
    incoming,
    setFriends,
    setRequests,
    setIncoming
  } = props;

  const currentUid = props.authUser.uid;

  const { language } = useContext(LanguageContext);

  const handleDelete = friendUid =>
    () => firebase.deleteFriend(currentUid, friendUid, friends, setFriends);

  const handleAccept = (friendUid) =>
    () => {
      if (!(users[currentUid].tables && users[currentUid].tables[friendUid])) {
        firebase.newTable(currentUid, friendUid);
      }
      firebase.acceptRequest(currentUid, friendUid, friends, requests, incoming, setRequests, setIncoming)
    };
  

  const handleDecline = (friendUid) => () =>
    firebase.declineRequest(currentUid, friendUid, requests, incoming, setRequests, setIncoming);
  

  const getLink = friendUid => {
    // console.log('getLink', users[currentUid], 'frieds', friends);
    // console.log('tableId', users[currentUid].tables[friendUid]);
    if (users && tables) {
      const tableId = users[currentUid].tables[friendUid];
      const gameId = tables[tableId] ? tables[tableId].games[0] : "";
      const link = `/games/${tableId}/${gameId}`;
      // console.log('link', link);
      return link;
    }
    return "/";
  };

  // console.log("users:", users, 'friends', friends, 'tables', tables);
  // console.log('', users[currentUid].tables[friendUid]);

  return (
    <div className="padding">
      <div className="flex-container flex-justify-between flex-align-baseline">
        <h1>{texts[language].friends['title']}</h1>
        <Link className="align-right" to={'/add-friend'} >{texts[language].friends['addFriend']}</Link>
      </div>
      {!!Object.keys(incoming).length &&
        <>
          <h3>{texts[language].friends['newRequest']}</h3>
          <p>{texts[language].friends['newRequestDescription']}</p>
        </>
      }

      <ul className="list-group">
        {incoming && users && users[currentUid]
          ? Object.keys(incoming).map(key => (
              <li key={key}>
                <span style={{
                      cursor: "pointer",
                      flex: "1 0 auto"
                    }}>
                  {users[key] ? users[key].username : "N/A..."}
                  {users[key] && users[key].points
                    ? ` (${users[key].points} pts)`
                    : " (0 pts)"}
                  {users[key].points &&
                    users[key].points >= RANKS.MASTER &&
                    users[key].points < RANKS.GRAND_MASTER && (
                      <span className="rank-master"> ♛ </span>
                    )}
                  {users[key].points &&
                    users[key].points >= RANKS.GRAND_MASTER && (
                      <span className="rank-grand-master"> ♚ </span>
                    )}
                </span>
                  {true && (
                  <span
                    style={{
                      cursor: "pointer",
                      flex: "0 0 auto",
                      marginRight: "2vw"
                    }}
                    onClick={handleAccept(key)}
                  >
                    {texts[language].friends['acceptRequest']}
                  </span>
                )}
                <span style={{ cursor: "pointer" }} onClick={handleDecline(key)}>
                  {texts[language].friends['declineRequest']}
                </span>
              </li>
            ))
          : null}
      </ul>
      <h3>{texts[language].friends['friends']}</h3>
      <p>{!!Object.keys(friends).length ? texts[language].friends['description'] : texts[language].friends['noFriends']}</p>
      <ul className="list-group">
        {friends && users && users[currentUid]
          ? Object.keys(friends).map(key => (
              <li key={key}>
                {users[currentUid].tables && users[currentUid].tables[key] ? (
                  <Link to={getLink(key)}>
                    {users[key] ? users[key].username : "N/A..."}
                    {users[key] && users[key].points
                      ? ` (${users[key].points} pts)`
                      : " (0 pts)"}
                    {users[key].points &&
                      users[key].points >= RANKS.MASTER &&
                      users[key].points < RANKS.GRAND_MASTER && (
                        <span className="rank-master"> ♛ </span>
                      )}
                    {users[key].points &&
                      users[key].points >= RANKS.GRAND_MASTER && (
                        <span className="rank-grand-master"> ♚ </span>
                      )}
                  </Link>
                ) : (
                  <span>
                    {users[key] ? users[key].username : "N/A..."}
                    {users[key] && users[key].points
                      ? ` (${users[key].points} pts)`
                      : " (0 pts)"}
                    {users[key].points &&
                      users[key].points >= RANKS.MASTER &&
                      users[key].points < RANKS.GRAND_MASTER && (
                        <span className="rank-master"> ♛ </span>
                      )}
                    {users[key].points &&
                      users[key].points >= RANKS.GRAND_MASTER && (
                        <span className="rank-grand-master"> ♚ </span>
                      )}
                  </span>
                )}
                <span style={{ cursor: "pointer" }} onClick={handleDelete(key)}>✕</span>
              </li>
            ))
          : null}
      </ul>
    </div>
  );
}

const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withAuthorization(condition)
)(Friends);
