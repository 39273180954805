import React, { Component } from "react";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withAuthorization, withAuthentication } from "../Session";
import { withFirebase } from "../Firebase";
import IdleTimer from "react-idle-timer";

import { colors } from "../../constants/colors";
import { newGame } from "../../constants/newGame";
import { baradun, urobo } from "../../utils";

import Scores from "./Scores";
import Board from "./Board";
import Loader from "../Loader";
import Overlay from "./Overlay";
import Button from "../Button";

import "./game.css";

const cardsCount = 12;

const INITIAL_STATE = newGame;

class Game extends Component {
  constructor(props) {
    super(props);

    const activePlayer = baradun();
    const { tableId, gameId } = props.match.params;
    const currentUid = props.authUser.uid;
    const friendUid = false;

    // console.log(props);

    this.state = {
      ...INITIAL_STATE,
      tableId,
      gameId,
      players: [currentUid, friendUid],
      cards: null,
      activePlayer,
      startedBy: activePlayer,
      scores: [0, 0],
      idle: false
    };

    this.onIdle = this.onIdle.bind(this);
    this.onContinue = this.onContinue.bind(this);
    this.onReset = this.onReset.bind(this);
  }

  componentDidMount() {
    const { firebase } = this.props;
    const { gameId, tableId } = this.state;

    const gameRef = firebase.game(tableId, gameId);
    const self = this;
    // gameRef.set({ ...self.state })
    gameRef.on("value", snapshot => {
      const state = snapshot.val();
      self.setState({
        ...state
      });
    });
  }

  updateGame(updates) {
    const { firebase } = this.props;
    const { tableId, gameId } = this.state;
    const gameRef = firebase.game(tableId, gameId);
    gameRef.update({
      ...updates
    });
  }

  onContinue() {
    this.setState({
      idle: false
    });
  }

  onReset = () => {
    const { startedBy, tableId, gameId, players } = this.state;
    const updates = {
      ...INITIAL_STATE,
      tableId,
      gameId,
      players,
      cards: Array(cardsCount).fill(1),
      activePlayer: baradun(startedBy),
      startedBy: baradun(startedBy),
      scores: [0, 0],
      idle: false
    };
    this.setState(
      {
        ...updates
      },
      () => {
        this.updateGame(updates);
      }
    );
  };

  onIdle() {
    this.setState({
      idle: true
    });
  }

  getWinnerIndex(scores, activePlayer) {
    if (scores[0] + scores[1] < cardsCount / 2) {
      return undefined;
    }
    return scores[0] === scores[1]
      ? activePlayer
      : scores[0] > scores[1]
      ? 0
      : 1;
  }

  onPlayCard = (index, isActive) => {
    const { users } = this.props;
    const { activePlayer, lead, cards, scores, tableId, players } = this.state;
    let updates;
    // leading card
    if (isActive && !lead) {
      cards[index - 1] = 0;
      updates = {
        cards,
        lead: index,
        activePlayer: baradun(activePlayer)
      };
      this.setState(
        {
          ...updates
        },
        () => {
          this.updateGame(updates);
        }
      );
    }
    // second card
    if (isActive && lead) {
      cards[index - 1] = 0;
      updates = {
        cards,
        second: index,
        activePlayer: false
      };
      this.setState(
        {
          ...updates
        },
        () => {
          // see who won the trick
          const isSecondStronger = urobo(index, lead, cardsCount);
          let trickWonBy;
          // swap players if trick won by second
          if (isSecondStronger) {
            trickWonBy = activePlayer;
          } else {
            trickWonBy = baradun(activePlayer);
          }
          // update winners score
          scores[trickWonBy] += 1;
          this.updateGame(updates);
          // after a second clear table update game in db
          const timer = setTimeout(updates => {
            updates = {
              activePlayer: trickWonBy,
              scores,
              lead: false,
              second: false
            };
            this.setState(
              {
                // is it necessary?
                ...updates
              },
              () => {
                const { cards } = this.state;
                // when end of game
                if (cards.every(el => el === 0)) {
                  updates.endOfGame = true;
                  this.setState(
                    {
                      ...updates
                    },
                    () => {
                      const {
                        firebase: { archiveGame }
                      } = this.props;
                      const { startedBy, activePlayer, scores } = this.state;
                      // get current points
                      const userPoints = [
                        users[players[0]].points || 0,
                        users[players[1]].points || 0
                      ];
                      // calculate winner #can be buggy
                      const winnerIndex = this.getWinnerIndex(
                        scores,
                        activePlayer
                      );
                      const winner = {
                        id: players[winnerIndex],
                        points: parseInt(userPoints[winnerIndex], 10) + 1
                      };
                      // update game results
                      const result = [...scores, winnerIndex, startedBy];
                      // archive game, then update existing game
                      archiveGame(tableId, result, winner).then(() => {
                        this.updateGame(updates);
                      });
                    }
                  );
                } else {
                  this.updateGame(updates);
                }
              }
            );
            clearTimeout(timer);
          }, 1000);
        }
      );
    }
  };

  onToScoreboard = () => {
    const { history } = this.props;
    const { tableId } = this.state;
    history.push(`/games/${tableId}`);
  };

  render() {
    const { users, authUser } = this.props;
    const {
      cards,
      players,
      activePlayer,
      startedBy,
      lead,
      second,
      scores,
      idle
      // endOfGame
    } = this.state;
    const currentUid = authUser.uid;
    const isActivePlayerCurrent = currentUid === players[activePlayer];
    const playerNames = Object.keys(users).length && {
      even: players[0] && users[players[0]].username,
      odd: players[1] && users[players[1]].username
    };
    // console.log(renderCount++, this.state, this.props);
    // debugger;

    const winner = this.getWinnerIndex(scores, activePlayer);
    const coloredBackground = colors[(second || lead) - 1] || "transparent";

    return !cards ? (
      <Loader />
    ) : (
      <IdleTimer
        ref={ref => {
          this.idleTimer = ref;
        }}
        element={document}
        onIdle={this.onIdle}
        debounce={250}
        timeout={1000 * 60 * 5}
      >
        <div
          className="flex-container flex-column flex-grow-1 flex-align-center padding"
          style={{
            backgroundColor: coloredBackground
          }}
        >
          <div className="constrain-width flex-container flex-column flex-grow-1 stretch-horizontally">
            <Board
              lead={lead}
              second={second}
              cards={cards}
              activePlayer={activePlayer}
              isActivePlayerCurrent={isActivePlayerCurrent}
              cardsCount={cardsCount}
              colors={colors}
              onPlayCard={this.onPlayCard}
            />
          <h3 style={{ textAlign: "center", fontWeight: "300" }}>{playerNames.even} : {playerNames.odd}</h3>
            <Scores
              scores={scores}
              names={playerNames}
              startedBy={startedBy}
              winner={winner}
            />
            <div className="flex-container flex-justify-between padding stretch-horizontally">
              <Button onClick={this.onReset} text="restart" />
              <Button secondary onClick={this.onIdle} text="rules" />
              <Button secondary onClick={this.onToScoreboard} text="scoreboard" />
            </div>
            {idle && (
              <Overlay onContinue={this.onContinue} onReset={this.onReset} />
            )}
          </div>
        </div>
      </IdleTimer>
    );
  }
}

const condition = authUser => !!authUser;

export default compose(
  withRouter,
  withFirebase,
  withAuthentication,
  withAuthorization(condition)
)(Game);
