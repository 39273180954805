export const sounds = [
  {
    base: "V_5", // 1
    c2: "V_6",
    c4: "V_8",
    c6: "V_10",
    c12: "V_4",
    c10: "V_2",
    c8: "V_0"
  },
  {
    base: "V_6", // 2
    c3: "V_7",
    c5: "V_9",
    c7: "V_11",
    c1: "V_5",
    c11: "V_3",
    c9: "V_1"
  },
  {
    base: "V_7", // 3
    c4: "V_8",
    c6: "V_10",
    c8: "V_12",
    c2: "V_6",
    c12: "V_4",
    c10: "V_2"
  },
  {
    base: "V_8", // 4
    c5: "V_9",
    c7: "V_11",
    c9: "V_13",
    c3: "V_7",
    c1: "V_5",
    c11: "V_3"
  },
  {
    base: "V_9", // 5
    c6: "V_10",
    c8: "V_12",
    c10: "V_14",
    c4: "V_8",
    c2: "V_6",
    c12: "V_4"
  },
  {
    base: "V_10", // 6
    c7: "V_11",
    c9: "V_13",
    c11: "V_15",
    c5: "V_9",
    c3: "V_7",
    c1: "V_5"
  },
  {
    base: "V_11", // 7
    c8: "V_12",
    c10: "V_14",
    c12: "V_16",
    c6: "V_10",
    c4: "V_8",
    c2: "V_6"
  },
  {
    base: "V_12", // 8
    c9: "V_13",
    c11: "V_15",
    c1: "V_17",
    c7: "V_11",
    c5: "V_9",
    c3: "V_7"
  },
  {
    base: "V_13", // 9
    c10: "V_14",
    c12: "V_16",
    c2: "V_18",
    c8: "V_12",
    c6: "V_10",
    c4: "V_8"
  },
  {
    base: "V_14", // 10
    c11: "V_15",
    c1: "V_17",
    c3: "V_19",
    c9: "V_13",
    c7: "V_11",
    c5: "V_9"
  },
  {
    base: "V_15", // 11
    c12: "V_16",
    c2: "V_18",
    c4: "V_20",
    c10: "V_14",
    c8: "V_12",
    c6: "V_10"
  },
  {
    base: "V_16", // 12
    c1: "V_17",
    c3: "V_19",
    c5: "V_21",
    c11: "V_15",
    c9: "V_13",
    c7: "V_11"
  }
];
