import React, { useContext } from 'react';
import { LanguageContext } from "../../contexts/LanguageContext";
import { texts } from "../../intl/lang";

import { withAuthorization } from '../Session';

const HomePage = () => {
  const { language } = useContext(LanguageContext);

  return (
  <div className="padding">
    <h1 className="align-left margin-top">
      {texts[language].howToPlay.title}
    </h1>
    <ol style={{ marginLeft: "2vh" }} className="align-left">
      {texts[language].howToPlay.rules.map(text => (
        <li key={text}>{text}</li>
      ))}
    </ol>
  </div>
)};

const condition = authUser => !!authUser;

export default withAuthorization(condition)(HomePage);
