import React from 'react';
import { compose } from 'recompose';
import { withAuthorization } from '../Session';
import { withFirebase } from '../Firebase';
import { SignUpLink } from '../SignUp';

import * as ROLES from '../../constants/roles';

const UserList = ({ users }) => (
  <ul className="list-group">
    {Object.keys(users).map(key => (
      <li key={key} className="flex-container flex-column">
        <span className="flex-item">
          <strong>Username:</strong> {users[key].username}
        </span>
        <span>
          <strong>E-Mail:</strong> {users[key].email}
        </span>
        <span>
          <strong>ID:</strong> {key}
        </span>
      </li>
    ))}
  </ul>
);

function AdminPage(props) {
  const { users } = props;
  return (
    <div className="padding">
      <h1>Admin</h1>
      <UserList users={users} />
      <SignUpLink />
    </div>
  );
}

const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN];

export default compose(
  withAuthorization(condition),
  withFirebase,
)(AdminPage);
