export const texts = {
  hu: {
    buttons: {
      restart: "Újraindítás",
      continue: "Folytatás",
      switchTo: "EN",
      rules: "Játékszabály",
      scoreboard: "Eredmények"
    },
    howToPlay: {
      title: "Hogyan urobózzunk?",
      rules: [
        "Az egyik játekos a fehér, a másik a fekete zsetonokkal játszik.",
        "Nincs legerősebb és leggyengébb zseton: minden zseton az óramutató járásával megegyező irányban üti a körben előtte levő 3 zsetont és vereséget szenved az utána következő 3-tól.",
        "Ütéskényszer nincs.",
        "Az hív, aki ütött.",
        "Az nyer, aki többet üt, egyenlőség esetén az utolsó ütés számít."
      ]
    },
    nav: {
      landing: "Urobo",
      signIn: "Belépés",
      home: "Játékszabály",
      friends: "Játék",
      signOut: "Kilépés"
    },
    landing: {
      hero: {
        title: "Urobo™",
        tagline: "A játék, amelynek sosincs vége",
        description: "Az Urobo egy újfajta, körkörös hierarchiára épülő logikai játék - nemcsak tudósoknak."
      },
      circularHierarchy: {
        title: "Mit jelent a körköros hierarchia?",
        description: ["Fekete játszik fehér ellen",
          "Nincs legerősebb vagy leggyengébb zseton",
          "Egy zseton éretéke annak elhelyezkedésétől függ"]
      } 
    },
    signUp: {
      title: "Feliratkozás",
      fullName: "Név",
      email: "Email cím",
      password: "Jelszó",
      confirmPassword: "Jelszó újra",
      buttonText: "Feliratkozás",
      text: "Érdekel a játék?",
      signUp: "Iratkozz fel"
    },
    signIn: {
      title: "Belépés",
      email: "Email cím",
      password: "Jelszó",
      buttonText: "Bejelentkezés",
      forgotten: "Elfelejtett jelszó?"
    },
    friends: {
      title: "Játék barátokkal",
      noFriends: "Adj a listához egy barátot",
      description: "Kattints egy névre a játék elindításához",
      addFriend: "+ Barátok kihívása",
      request: "Kihívás",
      cancelRequest: "Kihívás törlése",
      acceptRequest: "Kihívás elfogadása",
      declineRequest: "Kihívás elutasítása",
      friends: "Barátok",
      newRequest: "Új kihívás",
      newRequestDescription: "Fogadd el, vagy utasítsd vissza a kihívásokat!"
    },
    addFriend: {
      title: "Barátok kihívása"
    },
    account: {
      title: "Fiókoldal",
      newPassword: "Új jelszó",
      confirmNewPassword: "Új jelszó még egyszer",
      reset: "Új jelszót kérek"
    },
    games: {
      title: "Eredmények"
    }
  },


  /*** EN ***/
  en: {
    buttons: {
      restart: "Restart",
      continue: "Continue",
      switchTo: "HU",
      rules: "How to play",
      scoreboard: "Scores"
    },
    howToPlay: {
      title: "How to play Urobo",
      rules: [
        "Player one plays the white tiles, player two plays the black tiles.",
        "There are no stronger or weaker tiles, no trump suits, no hierarchy.",
        "The three tiles (clockwise) following the one which has just been played are stronger, and the three (counter-clockwise) before it, are weaker.",
        "The strongest tile wins the round. You can pass by playing a weaker tile.",
        "Whoever wins the round begins the next one.",
        "Whoever wins the most rounds, wins the game. In case of a tie, the player who won the final round wins the game."
      ]
    },
    nav: {
      landing: "Urobo",
      signIn: "Sign in",
      home: "How to play",
      friends: "Play",
      signOut: "Sign out"
    },
    landing: {
      hero: {
        title: "Urobo™",
        tagline: "The game that never ends",
        description:
          "An immersive logic game based on circular hierarchy – not only for scientists.",
      },
      circularHierarchy: {
        title: "Understanding circular hierarchy",
        description: ["Black tiles play against white tiles",
          "No tiles are the strongest or the weakest",
          "The strength of each tile varies based on their position"]
      } 
    },
    signUp: {
      title: "Sign up",
      fullName: "Full name",
      email: "Email address",
      password: "Password",
      confirmPassword: "Confirm password",
      buttonText: "Sign up",
      text: "Interested?",
      signUp: "Sign up here"
    },
    signIn: {
      title: "Sign in",
      email: "Email address",
      password: "Password",
      buttonText: "Sign in",
      forgotten: "Forgotten password?"
    },
    friends: {
      title: "Play with friends",
      noFriends: "Add friends first",
      description: "Click name to start playing with",
      addFriend: "+ Invite friends",
      request: "Request game",
      cancelRequest: "Cancel request",
      acceptRequest: " Accept request",
      declineRequest: "Decline request",
      friends: "Friends",
      newRequest: "New friend requests",
      newRequestDescription: "Accept or decline requests"
    },
    addFriend: {
      title: "Add friends"
    },
    account: {
      title: "Account page",
      newPassword: "New password",
      confirmNewPassword: "Confirm new password",
      reset: "Reset my password"
    },
    games: {
      title: "Scores"
    }
  }
};
