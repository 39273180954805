import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import { firebaseConfig as config } from '../../constants/firebaseConfig';
import { newGame } from '../../constants/newGame';
import { baradun } from '../../utils';

class Firebase {
  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
    this.db = app.database();
  }

  // *** Auth API ***
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);
  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);
  doSignOut = () =>
    this.auth.signOut();
  doPasswordReset = email =>
    this.auth.sendPasswordResetEmail(email);
  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API *** //
  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .once('value')
          .then(snapshot => {
            const dbUser = snapshot.val();
            // default empty roles
            if (!dbUser.roles) {
              dbUser.roles = {};
            }

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              ...dbUser,
            };

            next(authUser);
          });
      } else {
        fallback();
      }
    });

  // *** User API ***
  user = uid => this.db.ref(`users/${uid}`);
  users = () => this.db.ref('users');
  friends = uid => this.db.ref(`users/${uid}/friends`);
  requests = uid => this.db.ref(`users/${uid}/requests`);
  incoming = uid => this.db.ref(`users/${uid}/incoming`);
  userGames = uid => this.db.ref(`users/${uid}/tables`);
  gameDetails = (tableId, gameId) => this.db.ref(`games/${tableId}/${gameId}/players`);

  newFriend = (uid, friends, friendUid) => {
    let updates = {};
    updates[`users/${uid}/friends`] = { ...friends, [friendUid]: true };
    return this.db.ref().update(updates);
  }

  deleteFriend = async (uid, friendUid, friends, setFriends) => {
    let updates = {};
    let friendFriends = {};
    const newFriends = Object.assign({}, friends);
    setFriends(newFriends);
    await this.friends(friendUid).on("value", snapshot => {
      friendFriends = (snapshot.val() || {});
      delete newFriends[friendUid];
      delete friendFriends[uid];
      updates[`users/${uid}/friends`] = { ...newFriends };
      updates[`users/${friendUid}/friends`] = { ...friendFriends };
    });
    return this.db.ref().update(updates);
  };

  newRequest = (uid, friendUid, requests, incoming) => {
    let updates = {};
    updates[`users/${uid}/requests`] = { ...requests, [friendUid]: true };
    updates[`users/${friendUid}/incoming`] = { ...incoming, [uid]: true };
    return this.db.ref().update(updates);
  }

  deleteRequest = (uid, friendUid, requests, incoming, setRequests, setIncoming) => {
    let updates = {};
    const newRequests = Object.assign({}, requests);
    const newIncoming = Object.assign({}, incoming);
    delete newRequests[friendUid];
    delete newIncoming[uid];
    setRequests(newRequests);
    setIncoming(newIncoming);
    updates[`users/${uid}/requests`] = { ...newRequests };
    updates[`users/${friendUid}/incoming`] = { ...newIncoming };
    return this.db.ref().update(updates);
  }

  declineRequest = (uid, friendUid, requests, incoming, setRequests, setIncoming) => {
    let updates = {};
    const newRequests = Object.assign({}, requests);
    const newIncoming = Object.assign({}, incoming);
    delete newRequests[uid];
    delete newIncoming[friendUid];
    setRequests(newRequests);
    setIncoming(newIncoming);
    updates[`users/${friendUid}/requests`] = { ...newRequests };
    updates[`users/${uid}/incoming`] = { ...newIncoming };
    return this.db.ref().update(updates);
  }

  acceptRequest = async (uid, friendUid, friends, requests, incoming, setRequests, setIncoming) => {
    let updates = {};
    let friendFriends = {};
    const newRequests = Object.assign({}, requests);
    const newIncoming = Object.assign({}, incoming);
    await this.friends(friendUid).on("value", snapshot => {
      friendFriends = (snapshot.val() || {});
      delete newRequests[uid];
      delete newIncoming[friendUid];
      setRequests(newRequests);
      setIncoming(newIncoming);
      updates[`users/${friendUid}/requests`] = { ...newRequests };
      updates[`users/${uid}/incoming`] = { ...newIncoming };
      updates[`users/${uid}/friends`] = { ...friends, [friendUid]: true };
      updates[`users/${friendUid}/friends`] = { ...friendFriends, [uid]: true };
    });
    return this.db.ref().update(updates);
  }

  // *** State API ***
  // friend = (uid) => this.db.ref(`state/${uid}/friend`);
  // currentGame = (uid) => this.db.ref(`state/${uid}/currentGame`);

  // *** Game API ***
  newTable = (host, guest) => {
    let updates = {};
    const activePlayer = baradun();
    const tableId = this.db.ref(`tables`).push().key;
    const gameId = this.db.ref(`games`).push().key;
    const game = Object.assign(newGame, {
      players: [host, guest],
      host,
      gameId,
      tableId,
      activePlayer,
      currentPlayer: activePlayer
    });
    updates[`tables/${tableId}`] = { host, guest, games: [gameId] };
    updates[`users/${host}/tables/${guest}`] = tableId;
    updates[`users/${guest}/tables/${host}`] = tableId;
    updates[`games/${tableId}/${gameId}`] = game;
    updates[`users/${game.players[0]}/games/${gameId}`] = true;
    updates[`users/${game.players[1]}/games/${gameId}`] = true;
    return this.db.ref().update(updates);
  }
  table = tableId =>
    this.db.ref(`tables/${tableId}`);
  tables = () =>
    this.db.ref('tables');

  archiveGame = (tableId, result, winner) => {
    const resultId = this.db.ref(`tables/${tableId}/results`).push().key;
    let updates = {};
    updates[`tables/${tableId}/results/${resultId}`] = result;
    updates[`users/${winner.id}/points/`] = winner.points;
    return this.db.ref().update(updates);
  }

  newGame = (game, tableId) => {
    let updates = {};
    const gameId = this.db.ref(`games`).push().key;
    game.gameId = gameId;
    updates[`games/${tableId}/${gameId}`] = game;
    updates[`tables/${tableId}/games`] = [gameId];
    updates[`users/${game.players[0]}/games/${gameId}`] = true;
    updates[`users/${game.players[1]}/games/${gameId}`] = true;
    return this.db.ref().update(updates);
  }
  game = (tableId, gameId) =>
    this.db.ref(`games/${tableId}/${gameId}`);
  games = tableId =>
    this.db.ref(`games/${tableId}`);
  root = () =>
    this.db.ref('/');
}

export default Firebase;
