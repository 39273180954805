import React, { useContext } from 'react';

import { LanguageContext } from "../../contexts/LanguageContext";
import { texts } from "../../intl/lang";

import PasswordChangeForm from '../PasswordChange';
import { withAuthorization } from '../Session';

const AccountPage = () => {
  const { language } = useContext(LanguageContext);

  return (
  <div className="padding">
    <h1>{texts[language].account['title']}</h1>
    <PasswordChangeForm />
  </div>
)}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(AccountPage);
