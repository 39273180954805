export function baradun(number) {
  if(!arguments.length) {
    return Math.round(Math.random());
  }
  return number === 0 ? 1 : 0;
}

export const urobo = (second, lead, cardsCount) => {
  if(second === lead) {
    return false;
  }
  return Math.sin((second - lead) * 360 / cardsCount * Math.PI / 180) > 0;
}
