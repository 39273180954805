import React, { Component, useContext } from 'react';
import { Link } from 'react-router-dom';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

import { LanguageContext } from "../../contexts/LanguageContext";
import { texts } from "../../intl/lang";

const PasswordForgetPage = () => {
  const { language } = useContext(LanguageContext);

  return (
  <div className="padding">
    <h1>{texts[language].signIn['forgotten']}</h1>
    <PasswordForgetForm />
  </div>
)};

const INITIAL_STATE = {
  email: '',
  error: null,
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error } = this.state;

    const isInvalid = email === '';

    return (
      <LanguageContext.Consumer>
        {context =>
          <form onSubmit={this.onSubmit}>
            <input
              name="email"
              value={this.state.email}
              onChange={this.onChange}
              type="text"
              placeholder={context.language && texts[context.language].signIn['email']}
            />
            <button disabled={isInvalid} type="submit">
              {context.language && texts[context.language].account['reset']}
            </button>

            {error && <p>{error.message}</p>}
          </form>
        }
      </LanguageContext.Consumer>
    );
  }
}

const PasswordForgetLink = () => {
  const { language } = useContext(LanguageContext);

  return (
  <p>
    <Link to={ROUTES.PASSWORD_FORGET}>{texts[language].signIn['forgotten']}</Link>
  </p>
)};

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };
