import React, { useState, useEffect, useCallback, useMemo } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { compose } from "recompose";
import { LanguageContext } from "../../contexts/LanguageContext";

import Navigation from "../Navigation";
import Footer from "../Footer";
import LandingPage from "../Landing";
import HomePage from "../Home";
import SignUpPage from "../SignUp";
import SignInPage from "../SignIn";
import PasswordForgetPage from "../PasswordForget";
import AddFriend from "../AddFriend";
import Friends from "../Friends";
import GamePage from "../Game";
import GamesPage from "../Games";
import AccountPage from "../Account";
import AdminPage from "../Admin";

import * as ROUTES from "../../constants/routes";
import { withAuthentication } from "../Session";
import { withFirebase } from "../Firebase";

import "./app.css";
import "./form.css";

const App = props => {
  const {
    firebase,
    firebase: { auth }
  } = props;
  const [friends, setFriends] = useState({});
  const [requests, setRequests] = useState({});
  const [incoming, setIncoming] = useState({});
  const [users, setUsers] = useState({});
  const [tables, setTables] = useState({});
  const [language, _setLanguage] = useState(localStorage.getItem('urobo.language') || "hu");
  const currentUid = auth.currentUser ? auth.currentUser.uid : null;

  const setLanguage = useCallback((value) => {
    _setLanguage(value);
    localStorage.setItem('urobo.language', value)
  }, [])

  useEffect(() => {
    if (!localStorage.getItem('urobo.language')) {
      localStorage.setItem('urobo.language', language)
    }
  }, [language])

  useEffect(() => {
    if (currentUid) {
      firebase.friends(currentUid).on("value", snapshot => {
        setFriends(snapshot.val() || {});
      });
    }
  }, [firebase, currentUid]);

  useEffect(() => {
    if (currentUid) {
      firebase.requests(currentUid).on("value", snapshot => {
        setRequests(snapshot.val() || {});
      });
    }
  }, [firebase, currentUid]);

  useEffect(() => {
    if (currentUid) {
      firebase.incoming(currentUid).on("value", snapshot => {
        setIncoming(snapshot.val() || {});
      });
    }
  }, [firebase, currentUid]);

  useEffect(() => {
    firebase.users().on("value", snapshot => {
      setUsers(snapshot.val());
    });
  }, [firebase]);

  useEffect(() => {
    firebase.tables().on("value", snapshot => {
      setTables(snapshot.val());
    });
  }, [firebase, currentUid]);

  const notification = useMemo(() => !!Object.keys(incoming).length, [incoming]);

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      <Router>
        <div className="App flex-container flex-column flex-align-center flex-justify-stretch">
          <header className="flex-item">
            <Navigation notification={notification} />
          </header>
          <main className="main flex-item flex-grow-1 flex-container flex-column">
            <Route exact path={ROUTES.LANDING} component={LandingPage} />
            <Route exact path={ROUTES.HOME} component={HomePage} />
            <Route exact path={ROUTES.SIGN_UP} component={SignUpPage} />
            <Route exact path={ROUTES.SIGN_IN} component={SignInPage} />
            <Route
              exact
              path={ROUTES.PASSWORD_FORGET}
              component={PasswordForgetPage}
            />
            <Route
              exact
              path={ROUTES.FRIENDS}
              render={() => (
                <Friends
                  users={users}
                  tables={tables}
                  friends={friends}
                  setFriends={setFriends}
                  setRequests={setRequests}
                  setIncoming={setIncoming}
                  requests={requests}
                  incoming={incoming}
                />
              )}
            />
            <Route
              exact
              path={ROUTES.ADD_FRIEND}
              render={() => <AddFriend
                users={users}
                friends={friends}
                requests={requests}
                incoming={incoming}
                setRequests={setRequests}
                setIncoming={setIncoming}
              />}
            />
            <Route
              exact
              path={ROUTES.GAMES}
              render={() => <GamesPage users={users} tables={tables} />}
            />
            <Route
              exact
              path={ROUTES.GAME}
              render={() => <GamePage users={users} />}
            />
            <Route path={ROUTES.ACCOUNT} component={AccountPage} />
            <Route
              exact
              path={ROUTES.ADMIN}
              render={() => <AdminPage users={users} />}
            />
          </main>
          <Footer />
        </div>
      </Router>
    </LanguageContext.Provider>
  );
};

export default compose(
  withFirebase,
  withAuthentication
)(App);
