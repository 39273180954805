import React from "react";

export default function Scores(props) {
  const { scores, names, startedBy, winner } = props;

  return (
    names && (
      <div className="flex-container scores flex-justify-center flex-align-center">
        <div className="flex-container score primary position-relative">
          <span className="score__number">{scores[0]}</span>
          <span className="score__name">
            <span className="color-red">{startedBy === 0 && "⚑"}</span>
          </span>
          {winner === 0 && <div className="position-absolute crown">♛</div>}
        </div>
        <div className="flex-container score position-relative">
          <span className="score__number">{scores[1]}</span>
          <span className="score__name">
            <span className="color-red">{startedBy === 1 && "⚑"}</span>
          </span>
          {winner === 1 && <div className="position-absolute crown">♛</div>}
        </div>
      </div>
    )
  );
}
