import React, { useContext } from "react";
import { NavLink, withRouter } from "react-router-dom";
// import logo from '../../assets/logo_white.svg';
import SignOutButton from "../SignOut";
import { LanguageContext } from "../../contexts/LanguageContext";
import { texts } from "../../intl/lang";

import * as ROUTES from "../../constants/routes";
import * as ROLES from "../../constants/roles";

import { AuthUserContext } from "../Session";

import uroboLogoSmall from "../../assets/urobo-o-white.svg";
import LanguageSwitcher from "../LanguageSwitcher";


import "./navigation.css";

const Navigation = ({ notification }) => (
    <nav className="navigation">
      <AuthUserContext.Consumer>
        {authUser =>
          authUser ? (
            <NavigationAuth authUser={authUser} notification={notification}/>
          ) : (
            <NavigationNonAuth />
          )
        }
      </AuthUserContext.Consumer>
    </nav>
  );

const NavigationAuth = ({ authUser, notification }) => {
  const { language } = useContext(LanguageContext);

  return (
  <ul>
    {!!authUser.roles[ROLES.ADMIN] ? (
      <li className="brand-logo">
        <NavLink exact to={ROUTES.ADMIN}>
          <img
            src={uroboLogoSmall}
            width={25}
            height="auto"
            alt="Urobo - The game that never ends"
          />
        </NavLink>
      </li>
    ) : (
      <li className="brand-logo">
        <img
          src={uroboLogoSmall}
          width={25}
          height="auto"
          alt="Urobo - The game that never ends"
        />
      </li>
    )}
    <li>
      <NavLink exact to={ROUTES.HOME}>{texts[language].nav['home']}</NavLink>
    </li>
    <li>
      <NavLink exact to={ROUTES.FRIENDS} className={notification ? 'notification' : ''}>{texts[language].nav['friends']}</NavLink>
    </li>
    <li>
      <NavLink exact to={ROUTES.ACCOUNT}>{authUser.username}</NavLink>
    </li>
    <li>
      <SignOutButton />
    </li>
    <li>
      <LanguageSwitcher />
    </li>
  </ul>
)};

const NavigationNonAuth = () => {
  const { language } = useContext(LanguageContext);

  return (
  <ul>
    <li className="brand-logo">
      <img
        src={uroboLogoSmall}
        width={25}
        height="auto"
        alt="Urobo - The game that never ends"
      />
    </li>
    <li>
      <NavLink exact to={ROUTES.LANDING}>{texts[language].nav['landing']}</NavLink>
    </li>
    <li>
      <NavLink exact to={ROUTES.SIGN_IN}>{texts[language].nav['signIn']}</NavLink>
    </li>
    <li>
      <LanguageSwitcher />
    </li>
  </ul>
)};

export default withRouter(Navigation);
