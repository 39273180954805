import React, { useContext } from "react";
import { LanguageContext } from "../../contexts/LanguageContext";
import { texts } from "../../intl/lang";

export default function LanguageSwitcher() {
  const { language, setLanguage } = useContext(LanguageContext);
  return (
    <button
      className="button-link"
      onClick={() => setLanguage(language === "hu" ? "en" : "hu")}
      title={texts[language].buttons.switchTo}
    >
      <small>{texts[language].buttons.switchTo}</small>
    </button>
  );
}
