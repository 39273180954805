export const newGame = {
  gameId: 'test',
  tableId: true,
  players: [],
  host: true,
  endOfGame: false,
  cards: Array(12).fill(1),
  activePlayer: true,
  currentPlayer: true,
  lead: false,
  second: false,
  scores: [0, 0]
}
