import React, { Component, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import { SignUpLink } from '../SignUp';
import { PasswordForgetLink } from '../PasswordForget';

import { LanguageContext } from "../../contexts/LanguageContext";
import { texts } from "../../intl/lang";

import * as ROUTES from '../../constants/routes';

const SignInPage = () => {
  const { language } = useContext(LanguageContext);
  
  return (
  <div className="padding">
    <h1>{texts[language].signIn['title']}</h1>
    <SignInForm />
    <PasswordForgetLink />
    <SignUpLink />
  </div>
)};

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
  context: {}
};

class SignInFormBase extends Component {  
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.FRIENDS);
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error } = this.state;

    const isInvalid = password === '' || email === '';

    return (
      <LanguageContext.Consumer>
        {context =>
          <form onSubmit={this.onSubmit}>
            <input
              name="email"
              value={email}
              onChange={this.onChange}
              type="text"
              placeholder={context.language && texts[context.language].signIn['email']}
            />
            <input
              name="password"
              value={password}
              onChange={this.onChange}
              type="password"
              placeholder={context.language && texts[context.language].signIn['password']}
            />
            <button disabled={isInvalid} type="submit">
              {context.language && texts[context.language].signIn['buttonText']}
            </button>

            {error && <p>{error.message}</p>}
          </form>
        }
      </LanguageContext.Consumer>
    );
  }
}

const SignInForm = compose(
  withRouter,
  withFirebase,
)(SignInFormBase);

export default SignInPage;

export { SignInForm };
