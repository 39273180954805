import React from "react";
import { sounds } from "./sounds";
export default function Card(props) {
  const {
    activePlayer,
    isActivePlayerCurrent,
    visible,
    index,
    onPress,
    cardsCount,
    played,
    boardWidth,
    // color
  } = props;
  const isActive = (index - activePlayer) % 2 === 0 && isActivePlayerCurrent;
  const tileSize = boardWidth / (cardsCount / 1.6);
  const transforms = {
    transform: `rotate(${((index - 1) * 360) / cardsCount -
      (360 / cardsCount) * 2}deg) translateX(${boardWidth / 2.5}px)`,
    width: `${tileSize}px`,
    height: `${tileSize}px`,
    top: `${(boardWidth - tileSize) / 2}px`,
    left: `${(boardWidth - tileSize) / 2}px`,
    transition: "0.25s"
  };

  const justPlayed = played.lead === index || played.second === index;

  const soundfilename = !played.lead
    ? sounds[index - 1].base
    : sounds[played.lead - 1]["c" + index];

  const sound = soundfilename
    ? require(`../../assets/audio/${soundfilename}.mp3`)
    : null;
  const audio = soundfilename ? new Audio(sound) : null;

  return visible ? (
    isActive ? (
      <div
        className={`card${index % 2 === 0 ? " primary" : ""}${
          isActive ? " highlight" : ""
        } color-${index}`}
        style={{
          ...transforms
        }}
        onClick={() => {
          onPress(index, isActive);
          if (soundfilename) {
            audio.play();
          }
        }}
      />
    ) : (
      <div
        className={`card${index % 2 === 0 ? " primary" : ""} color-${index}`}
        style={{
          ...transforms
        }}
      />
    )
  ) : justPlayed ? (
    <div
      className={`card${
        index % 2 === 0 ? " primary" : ""
      } played color-${index}`}
      style={{
        ...transforms,
        transform: `rotate(${((index - 1) * 360) / cardsCount -
          (360 / cardsCount) * 2}deg) translateX(${boardWidth / 3.75}px)`
      }}
    />
  ) : (
    <div
      className="card disabled"
      style={{
        ...transforms
      }}
    />
  );
}
