import React, { Component, useContext } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';

import { LanguageContext } from "../../contexts/LanguageContext";
import { texts } from "../../intl/lang";

const SignUpPage = () => {
  const { language } = useContext(LanguageContext);
  return (
  <div className="padding">
    <h1>{texts[language].signUp['title']}</h1>
    <SignUpForm />
  </div>
)};

const INITIAL_STATE = {
  username: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  isAdmin: false,
  error: null,
};

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChangeCheckbox = event => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  onSubmit = event => {
    const { username, email, passwordOne, isAdmin } = this.state;
    const roles = {};

    if (isAdmin) {
      roles[ROLES.ADMIN] = ROLES.ADMIN;
    }

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        // Create a user in your Firebase realtime database
        return this.props.firebase
          .user(authUser.user.uid)
          .set({
            username,
            email,
            roles,
          })
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      // isAdmin,
      error,
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      username === '';

    return (
      <LanguageContext.Consumer>
      {context => 
        <form onSubmit={this.onSubmit}>
          <input
            name="username"
            value={username}
            onChange={this.onChange}
            type="text"
            placeholder={context.language && texts[context.language].signUp['fullName']}
          />
          <input
            name="email"
            value={email}
            onChange={this.onChange}
            type="text"
            placeholder={context.language && texts[context.language].signUp['email']}
          />
          <input
            name="passwordOne"
            value={passwordOne}
            onChange={this.onChange}
            type="password"
            placeholder={context.language && texts[context.language].signUp['password']}
          />
          <input
            name="passwordTwo"
            value={passwordTwo}
            onChange={this.onChange}
            type="password"
            placeholder={context.language && texts[context.language].signUp['confirmPassword']}
          />
          {/* <label>
            Admin:
            <input
              name="isAdmin"
              type="checkbox"
              checked={isAdmin}
              onChange={this.onChangeCheckbox}
            />
          </label> */}
          <button disabled={isInvalid} type="submit">
            {context.language && texts[context.language].signUp['buttonText']}
          </button>

          {error && <p>{error.message}</p>}
        </form>
      }
      </LanguageContext.Consumer>
    );
  }
}

const SignUpLink = ({ button }) => {
  const { language } = useContext(LanguageContext);

  return (
    <>
      <p>
        {texts[language].signUp['text']}
      </p>
      <p>
        <Link className={button && "button"} to={ROUTES.SIGN_UP} >{texts[language].signUp['signUp']}</Link>
      </p>
    </>
)};

const SignUpForm = compose (
  withRouter,
  withFirebase
)(SignUpFormBase);

export default SignUpPage;

export { SignUpForm, SignUpLink };
