import { useState, useEffect, useContext } from 'react';
import { LanguageContext } from "../contexts/LanguageContext";

const getVmin = (elem) => elem.clientWidth > elem.clientHeight ? elem.clientHeight : elem.clientWidth;
const getWidth = (ref) => ref && ref.current ? getVmin(ref.current) : 0;
const getStyle = (ref) => ref && ref.current && ref.current.clientWidth > ref.current.clientHeight
  ? { height: `${ref.current.clientHeight}px`, width: `${ref.current.clientHeight}px` }
  : { height: `${ref.current.clientWidth}px` }

export default function useCurrentWidth(ref) {
  // save current window width in the state object
  let [width, setWidth] = useState(getWidth(ref));
  let [style, setStyle] = useState({});
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    setWidth(getWidth(ref));
    setStyle(getStyle(ref));
  }, [ref, language])

  // in this case useEffect will execute only once because
  // it does not have any dependencies.
  useEffect(() => {
    // timeoutId for debounce mechanism
    let timeoutId = null;
    const resizeListener = () => {
      // prevent execution of previous setTimeout
      clearTimeout(timeoutId);
      // change width from the state object after 150 milliseconds
      timeoutId = setTimeout(() => {
        setWidth(getWidth(ref));
        setStyle(getStyle(ref));
      }, 150);
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    }
  }, [ref])

  return {
    width,
    style
  };
}
