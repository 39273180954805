export const colors = [
  "#f44336",
  "#ff9800",
  "#ffc107",
  "#cddc39",
  "#8bc34a",
  "#4caf50",
  "#009688",
  "#1e88e5",
  "#3f51b5",
  "#673ab7",
  "#9c27b0",
  "#e91e63"
];
