import React from 'react';
import './styles/loader.css'

import urob from '../../assets/urob.svg';

export default function Loader() {
  return (
    <div className="spinner__backdrop">
      <img src={urob} width={'auto'} height={58} alt="Urobo - The game that never ends"/>
      <div className="spinner">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
}
