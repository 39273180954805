import React, { useRef } from "react";
import useCurrentWidth from '../../hooks/useCurrentWidth';
import Card from "./Card";

export default function Board(props) {
  const {
    lead,
    second,
    cards,
    colors,
    activePlayer,
    isActivePlayerCurrent,
    cardsCount,
    onPlayCard
  } = props;

  const boardRef = useRef(null);
  const {width, style} = useCurrentWidth(boardRef);

  // console.log('boardWidth', width);

  return (
    <div className="game" ref={boardRef} >
      <div className="board" style={style}>
        {cards.map((card, i) => (
          <Card
            boardWidth={width}
            activePlayer={activePlayer}
            isActivePlayerCurrent={isActivePlayerCurrent}
            visible={card !== 0}
            index={i + 1}
            key={i}
            onPress={onPlayCard}
            cardsCount={cardsCount}
            played={{ lead, second }}
            color={colors[i]}
          />
        ))}
      </div>
    </div>
  );
}
