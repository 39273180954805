import React, { useContext } from "react";
import { LanguageContext } from "../../contexts/LanguageContext";
import { texts } from "../../intl/lang";
import Button from "../Button";
import Footer from "../Footer";
import uroboLogo from "../../assets/urobo-logo-black.svg";

import LanguageSwitcher from "../LanguageSwitcher";

export default function Overlay({ onReset, onContinue }) {
  const { language } = useContext(LanguageContext);
  return (
    <div className="overlay flex-container flex-column position-fixed stretch align-center flex-justify-between">
      <div className="padding flex-container flex-column">
        <img
          src={uroboLogo}
          className="margin-top margin-bottom"
          alt="Urobo, a game that never ends"
          width="150"
          height="auto"
          style={{ opacity: 0.95 }}
        />
        <h2 className="align-left margin-top">
          {texts[language].howToPlay.title}
        </h2>
        <ol style={{ marginLeft: "2vh" }} className="align-left">
          {texts[language].howToPlay.rules.map(text => (
            <li key={text}>{text}</li>
          ))}
        </ol>
      </div>
      <div>
        <div className="padding flex-container flex-justify-between stretch-horizontally ">
          <Button onClick={onReset} text="restart" />
          <Button onClick={onContinue} text="continue" />
          <LanguageSwitcher />
        </div>
        <Footer />
      </div>
    </div>
  );
}
